import {Icon} from '@iconify/react';
import Image from 'next/legacy/image';
import {useEffect, useRef, useState} from 'react';

interface Testimonial {
  name: string;
  title: string;
  image: JSX.Element;
  companyLogo: JSX.Element;
  text: string;
  noAvatar?: boolean;
}

interface TestimonialProps extends Testimonial {
  index: number;
  activeIndex?: number;
}

const TestimonialCard = ({
  name,
  title,
  image,
  companyLogo,
  text,
  index,
  activeIndex,
  noAvatar,
}: TestimonialProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (index === activeIndex) {
      ref.current?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    }
  }, [activeIndex, index]);

  return (
    <div
      className="flex h-[510px] w-[343px] shrink-0 flex-col justify-between rounded bg-[#D1D5DB] p-10 font-sans text-sm"
      ref={ref}
    >
      <div className="flex flex-col items-start gap-3">
        {companyLogo}
        <div className="text-gray-800">{text}</div>
      </div>
      <div className="flex flex-col items-start gap-1">
        {!noAvatar && <>{image}</>}
        <span className="font-bold text-gray-900">{name}</span>
        <span className="text-gray-800">{title}</span>
      </div>
    </div>
  );
};

const testimonials: Testimonial[] = [
  {
    name: 'Dan Gillespie',
    title: 'CTO, Indent',
    image: (
      <Image
        src="/testimonials/dan-gillespie.png"
        alt="A picture of Dan Gillespie."
        className="h-16 object-contain"
        height={64}
        width={64}
      />
    ),
    companyLogo: (
      <Image
        src="/logos/indent-dark.png"
        alt="Indent's logo"
        className="h-8 object-contain"
        width={111}
        height={24}
      />
    ),
    text: 'By making the root cause for most performance issues painfully obvious, Polar Signals Cloud lets our engineering teams skip the debate about why so they can focus on the fix.',
  },
  {
    name: 'Nikhil Benesch',
    title: 'CTO, Materialize',
    image: (
      <Image
        src="/testimonials/nikhil-benesch.png"
        alt="A picture of Nikhil Benesch."
        className="h-16 object-contain"
        height={64}
        width={64}
      />
    ),
    companyLogo: (
      <Image
        src="/logos/materialize-logo.png"
        alt="Materialize's logo"
        className="h-8 object-contain"
        width={111}
        height={32}
      />
    ),
    text: 'Polar Signals Cloud has become a critical product in our software lifecycle, from informing how and where to write high-performance code in development, to understanding how it behaves in production and using it to troubleshoot performance-related incidents. Nothing else gives us detail down to the process, thread, and line number and as actionable as Polar Signals Cloud can.',
  },
  {
    name: 'Jeroen Schepens',
    title: 'Head of Cloud Infrastructure, Oqton',
    image: <></>,
    companyLogo: (
      <Image
        src="/logos/oqton-black-logo.png"
        alt="Oqqton's logo"
        className="h-8 object-contain"
        width={111}
        height={32}
      />
    ),
    text: "There's really nothing quite like the magic of Polar Signals Cloud's simplicity. A single agent to deploy, no instrumentation of application code needed and all at practically unmeasurable overhead is a dream come true. We can just focus on getting value out of continuous profiling.",
    noAvatar: true,
  },
  {
    name: 'Xander Garbett',
    title: 'SRE, Thought Machine',
    image: (
      <Image
        src="/testimonials/xander-garbett.png"
        alt="A picture of Xander Garbett."
        className="h-16 object-contain"
        height={64}
        width={64}
      />
    ),
    companyLogo: (
      <Image
        src="/logos/thought-machine.png"
        alt="Thought Machine's logo"
        className="h-8 object-contain"
        width={111}
        height={32}
      />
    ),
    text: 'Within 15 minutes of using Polar Signals we noticed an unexpectedly large (55%) CPU usage by a single workload.',
  },
  {
    name: 'Jimmy Zelinskie',
    title: 'Co-Founder, Authzed',
    image: (
      <Image
        src="/testimonials/jimmy-zelinskie.png"
        alt="A picture of Jimmy Zelinskie."
        className="h-16 object-contain"
        height={64}
        width={64}
      />
    ),
    companyLogo: (
      <Image
        src="/logos/authzed-logo.png"
        alt="Authzed's logo"
        className="h-8 object-contain"
        width={111}
        height={32}
      />
    ),
    text: 'Polar Signals’ strategy with eBPF is the only way continuous profiling works with production workloads, anything else has too much overhead. We tried other tools and it was just performance prohibitive for us to use.',
  },
  {
    name: 'Rob Skillington',
    title: 'CEO, Chronosphere',
    image: (
      <Image
        src="/testimonials/rob-skillington.png"
        alt="A picture of Rob Skillington."
        className="h-16 object-contain"
        height={64}
        width={64}
      />
    ),
    companyLogo: (
      <Image
        src="/logos/chronosphere-logo.png"
        alt="Chronosphere's logo"
        className="h-8 object-contain"
        width={124}
        height={32}
      />
    ),
    text: 'Merging samples is one of the most powerful workflows continuous profiling enables!',
  },
  {
    name: 'Sandor Szücs',
    title: 'Platform Engineer, Zalando',
    image: (
      <Image
        src="/testimonials/sandor-szucs.png"
        alt="A picture of Sandor Szücs."
        className="h-16 object-contain"
        height={64}
        width={64}
      />
    ),
    companyLogo: (
      <Image
        src="/logos/zalando-logo.png"
        alt="Zalando's logo"
        className="h-8 object-contain"
        width={111}
        height={32}
        objectFit="cover"
      />
    ),
    text: 'Polar Signals allows us to troubleshoot and identify memory and CPU issues faster than ever before!',
  },
];

export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  return (
    <div className="flex flex-col gap-9 overflow-hidden">
      <div className="flex gap-4 overflow-x-auto">
        {testimonials.map((testimonial, idx) => {
          return (
            <TestimonialCard
              {...testimonial}
              key={testimonial.name}
              activeIndex={activeIndex}
              index={idx}
            />
          );
        })}
      </div>
      <div className="flex gap-5">
        <Icon
          icon="ph:arrow-circle-left-thin"
          fontSize={56}
          onClick={() => {
            setActiveIndex(((activeIndex ?? 0) - 1 + testimonials.length) % testimonials.length);
          }}
        />
        <Icon
          icon="ph:arrow-circle-right-thin"
          fontSize={56}
          onClick={() => {
            setActiveIndex(((activeIndex ?? 0) + 1) % testimonials.length);
          }}
        />
      </div>
    </div>
  );
};
